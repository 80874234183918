/* Footer.css */
.Learnfooter {
  background-color: #022139;
  color: white;
}
.Learnfooter-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px;
}
.Learnlogo {
  padding-left: 60px;
}
.Learnlogo li {
  list-style-type: none;
  cursor: pointer;
}

@media (min-width: 300px) and (max-width: 1200px) {
  .Learnfooter-container {
    flex-direction: column;
    padding: 0;
    align-items: start;
  }
  .Learnlogo {
    padding-left: 0;
    padding: 20px;
  }
}
