.Erp-background {
  background: linear-gradient(308deg, #40413f, #bcb595, #e68d09);
  background-size: 600% 100%;
  animation: gradient-animation 20s ease infinite;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 40px;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.content-Erp {
  flex: 50%;
  font-size: 3.5vh;
  color: white;
}

.image-container-Erp {
  flex: 50%;
}
.image-container-Erp img {
  width: 100%;
  height: 400px;
}

.custom-Erp-button-1 {
  background-color: #ff6600;
  color: white;
  text-align: center;
  font-size: 20px;
  border-radius: 20px;
  margin-top: 30px;
  transition: background-color 0.3s; /* Add a smooth transition */
  width: 200px; /* Adjust the width as needed */
  height: 50px;
  border: none;
}
.custom-Erp-button-1:hover {
  background-color: #ff8c40; /* Change the background color on hover */
  cursor: pointer; /* Change the cursor to a pointer on hover */
}

/* Active/Pressed state styles */
.custom-Erp-button-1:active {
  background-color: #d64d00; /* Change the background color when pressed */
}

.custom-Erp-button-2 {
  background-color: #ff6600;
  color: white;
  text-align: center;
  font-size: 18px;
  border-radius: 20px;
  margin-top: 30px;
  transition: background-color 0.3s; /* Add a smooth transition */
  width: 220px; /* Adjust the width as needed */
  height: 40px;
  border: none;
}
.custom-Erp-button-2:hover {
  background-color: #ff8c40; /* Change the background color on hover */
  cursor: pointer; /* Change the cursor to a pointer on hover */
}

/* Active/Pressed state styles */
.custom-Erp-button-2:active {
  background-color: #d64d00; /* Change the background color when pressed */
}

.process-line {
  list-style: none;
  margin: 100px auto 0;
  padding: 0;
  max-width: 870px;
  width: 100%;
  position: relative;
}

.process-line li {
  position: relative;
}

.process-line li:nth-child(even) {
  float: right;
  margin-top: 90px;
  margin-bottom: 70px;
  margin-right: 100px;
  margin-left: 500px;
  padding: 20px;
  height: 210px;
  position: relative;
}

.process-line li:nth-child(even)::before {
  /* Add this */
  content: "";
  position: absolute;
  top: 0;
  right: -50px; /* Adjust this value to control the position of the vertical line */
  height: 300px;
  width: 2px; /* Adjust this value to control the thickness of the vertical line */
  border-left: 2px dashed #000; /* Adjust this value to set the line color */
}

.process-line li:nth-child(even)::after {
  /* Add this */
  content: "";
  position: absolute;
  bottom: -90px; /* Adjust this value to control the position of the horizontal line */
  right: -50px; /* Adjust this value to control the position of the horizontal line */
  width: 56.5vw; /* Adjust this value to control the length of the horizontal line */
  height: 2px; /* Adjust this value to control the thickness of the horizontal line */
  border-top: 2px dashed #000; /* Adjust this value to set the line color */
}

.process-line li:nth-child(odd) {
  float: left;
  margin-top: 20px;
  margin-left: 100px;
  margin-right: 500px;
  padding: 20px;
  height: 210px;
  position: relative;
}

.process-line li:nth-child(odd)::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50px;
  height: 300px;
  width: 2px;
  border-right: 2px dashed #000;
}

.process-line li:nth-child(odd)::after {
  content: "";
  position: absolute;
  bottom: -90px;
  left: -50px;
  width: 56.5vw;
  height: 2px;
  border-bottom: 2px dashed #000;
}

.process-line li .step-number {
  position: absolute;
  top: 50%;
  left: -30px; /* Adjust this value for horizontal positioning */
  transform: translateY(-50%);
  background-color: #fff; /* Set the background color */
  padding: 5px 10px; /* Adjust padding as needed */
  border: 2px solid #000; /* Set the border styles */
  border-radius: 50%; /* Make it a circle */
  font-weight: bold;
}

.Timeline {
  min-width: 300px;
  max-width: 500px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 100px;
  font-family: sans-serif;
}

.Timeline-content {
  font-size: 1em;
  line-height: 1.75em;
  border-top: 2px dashed;
  /* -o-border-image: linear-gradient(to right, #743ad5 0%, #d53a9d 100%);
     border-image: linear-gradient(to right, #743ad5 0%, #d53a9d 100%);
  border-image-slice: 1; */
  border-width: 3px;
  margin: 0;
  padding: 40px;
  counter-increment: section;
  position: relative;
  color: #000000;
}
.Timeline-content:before {
  content: counter(section);
  position: absolute;
  border-radius: 30%;
  height: 1.25em;
  width: 1.25em;
  background-color: #ff6600;
  text-align: center;
  line-height: 1.25em;
  color: #ffffff;
  font-size: 1em;
}

.Timeline-content:nth-child(odd) {
  border-right: 3px dashed;
  padding-left: 0;
}
.Timeline-content:nth-child(odd):before {
  left: 100%;
  margin-left: -20px;
}

.Timeline-content:nth-child(even) {
  border-left: 3px dashed;
  padding-right: 0;
}
.Timeline-content:nth-child(even):before {
  right: 100%;
  margin-right: -20px;
}

.Timeline-content:first-child {
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.Timeline-content:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.erp-background2 {
  display: flex;
  flex-direction: row;
  margin-top: 100px;
  margin-bottom: 100px;
  background-color: #eee9e9;
  height: 80vh;
  align-items: center;
  padding: 30px;
}

.content-erp2 {
  flex: 50%;

  font-size: 3vh;
}

.image-container-erp2 {
  flex: 50%;
}
.image-container-erp2 img {
  width: 70%;
  height: 50%;
}

@media (min-width: 300px) and (max-width: 1200px) {
  .Erp-background {
    flex-direction: column;
    height: auto;
    margin-top: 40px;
  }

  .erp-background2 {
    flex-direction: column;
    height: auto;
    align-items: center;
  }
}
