.background-home-1 {
  background-color: #000;
  height: 100vh;
  display: flex !important;
  align-items: center;
  flex-direction: row !important;
  background-attachment: fixed;
  padding: 50px;
}

.flex-container1 {
  flex: 50%;
}

.flex-1 {
  flex: 50%;
}

.home-carousel-services {
  overflow: hidden;
}

.slide-content-services {
  align-items: center;
  padding-left: 30px;
}

.home-services {
  display: none;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.home-services.active {
  display: block;
  animation-name: slideInRight;
  animation-duration: 1s;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.background-home-2 {
  background: #0d2eaa;
  width: 100%;
  background-attachment: fixed;
  height: 100vh;
  padding: 50px;
  display: flex;
  flex-direction: row;
}

.why-choose-us {
  font-family: "Varela Round", sans-serif;

  font-weight: bold;

  color: white;
}

.home-carousel-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 60px;
}

.home-carousel-navigation {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  color: white;
  position: relative;
}

.home-carousel-navigation button {
  background-color: transparent;
  border: none;
  padding: 10px;
  margin-bottom: 50px;
  font-size: 1.6vw !important;
  color: #ffffff;
  font-weight: bolder;
  width: 25vw;
  text-align: left;
  cursor: pointer;
  position: relative;
}

.home-carousel-navigation button.active {
  color: #ff7300;
  background-color: transparent;
}

.home-carousel-navigation button::before {
  content: "";
  position: absolute;
  top: 45%;
  left: -1vw;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #ffffff;
  transform: translateY(-50%);
}

.home-carousel-navigation button.active::before {
  background-color: #ff7300;
}

.home-carousel-slides {
  display: flex;

  flex-direction: row;
  color: white;
  position: relative;
  overflow: hidden;
}

.home-slide {
  display: none;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.home-slide.active {
  display: block;
  animation-name: slideIn;
}

.slide-content {
  display: flex;
  align-items: center;
}

.slide-text h2 {
  margin-left: 10vw !important;
  font-family: "Varela Round", sans-serif;
  font-weight: bold;
}

.slide-text p {
  margin-left: 10vw !important;
  font-size: 18px;
  font-family: sans-serif;
}

.background-home-3 {
  background: #df9926;
  width: 100%;
  background-attachment: fixed;
  height: 100vh;
  padding: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-container-3 {
  flex: 50%;
}

.flex-3 {
  flex: 50%;
}

.image-home-3 {
  width: 100%;
  height: 80%;
}

.background-home-4 {
  background: #0f9ffa;
  background-attachment: fixed;
  width: 100%;
  height: 100vh;
  padding: 50px;
  text-align: center;
  align-items: center;
}

.product-home {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 50px;
}

.product-home-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 500px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.2);
  /* Adjust the last value (0.5) for the desired level of transparency */
  border-radius: 15px;
  /* Added 'solid' to specify border style */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.background-home-6 {
  background: #3c096c;
  background-attachment: fixed;
  width: 100%;
  height: 100vh;
  padding: 50px;
  text-align: center;
  align-items: center !important;

  color: white;
  font-family: "Varela Round", sans-serif;
}

.myDIV {
  display: flex;
  flex-direction: row !important;
  justify-content: space-evenly;
  margin-top: 80px !important;
}

.background-home-5 {
  background: #963596;
  width: 100%;
  background-attachment: fixed;
  height: 100vh;
  padding: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-carousel5 {
  flex: 50%;
}

.flex-container-5 {
  flex: 50%;
}

.image-home-5 {
  height: 60vmin;
  width: 100%;
}

.background-home-7 {
  background: #a284f5;
  background-attachment: fixed;
  width: 100%;
  height: 100vh;
  padding: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-7 {
  flex: 50%;
}

.flex-container-7 {
  flex: 50%;
}

.image-home-7 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 45vmin;
}

.custom-button-3 {
  background-color: #fd761b;
  color: white;
  text-align: center;
  font-size: 18px;
  border-radius: 20px;
  margin-top: 30px;
  transition: background-color 0.3s;
  /* Add a smooth transition */
  width: 150px;
  /* Adjust the width as needed */
  height: 40px;
  border: none;
  font-family: "Varela Round", sans-serif;
}

.custom-button-3:hover {
  background-color: #ff8c40;
  /* Change the background color on hover */
  cursor: pointer;
  /* Change the cursor to a pointer on hover */
}

/* Active/Pressed state styles */
.custom-button-3:active {
  background-color: #d64d00;
  /* Change the background color when pressed */
}

.heading-large-1 {
  font-weight: bold;
  margin-bottom: 30px;
  font-family: "Varela Round", sans-serif;
  color: white;
}

.heading-large-2 {
  font-size: 25px;
  font-weight: bold;
  font-family: "Varela Round", sans-serif;
  color: white;
}

.heading-large {
  font-size: 32px;
  font-weight: bold;
  font-family: "Varela Round", sans-serif;
  color: white;
}

.underline-input {
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid black !important;
  /* Change the color and style as desired */
  outline: none !important;
  background-color: transparent !important;
  padding: 5px 0;
  /* Adjust the padding as needed */
  width: 100%;
  /* Adjust the width as needed */
}

.underline-input:focus,
.underline-input:active,
.underline-input:hover {
  box-shadow: none !important;
  /* Remove the focus outline */
}

.paragraph {
  font-size: 20px;
  margin-left: 35px;
  color: white;
  font-family: "Varela Round", sans-serif;
}

.paragraph-1 {
  margin-top: 20px;
  font-size: 20px;
  color: white;
  font-family: "Varela Round", sans-serif;
}

.white-link {
  color: white;
  display: inline-block;
  position: relative;
  transition: transform 0.3s;
  outline: none;
  margin-bottom: 15px;
}

.white-link:hover {
  transform: scale(0.95);
}

.white-link:hover::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  z-index: -1;
  transition: transform 0.3s;
  transform: scale(1.05);
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  20%,
  80% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.home-carousel-container-1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.home-carousel-slides-1 {
  display: flex;

  flex-direction: row;
  color: white;
  position: relative;
  overflow: hidden;
}

.home-slide-1 {
  display: none;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.home-slide-1.active {
  display: block;
  animation-name: slideIn;
}

.slide-text-1 {
  height: 180px;
}

.background-home-8 {
  background: #b8860b;
  background-attachment: fixed;

  height: 100vh;
  padding: 50px;
  text-align: center;
  align-items: center;
}

.center .slick-center .CircleCarousel {
  transform: scale(1);
  z-index: 10000;
  transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
}

.CarouselImages {
  height: 350px;
  width: 350px;
  border-radius: 50%;
  position: relative;
  background: linear-gradient(319deg, #faff00 0%, #ff1000 37%, #ff6a00 100%);
  border: 4px solid transparent;
}

.center .CircleCarousel {
  transform: scale(0.7);
  opacity: 0.7;
  pointer-events: none;
}

.CircleCarouselName {
  text-align: center;
  font-size: 25px;
  font-family: sans-serif;
  color: white;
  margin-top: 10px;
  margin-right: 40px;
}

.home-image-1 {
  background-image: url(../assets/BackgroundImages/bg-2.png),
    url(../assets/BackgroundImages/bg-1.png);
  background-repeat: no-repeat;
  background-position: right 20px top 25%, left 0 top 100%;
}

.home-image-2 {
  background-image: url(../assets/BackgroundImages/bg-1-2.png),
    url(../assets/BackgroundImages/bg-2.png);
  background-repeat: no-repeat;
  background-position: right 0px top 100%, left 20px top 25%;
}

@media (min-width: 300px) and (max-width: 1200px) {
  .background-home-1 {
    flex-direction: column-reverse !important;

    padding: 20px;
    height: auto;
  }

  .home-image-1 {
    background-image: none;
  }

  .home-image-2 {
    background-image: none;
  }

  .flex-container1 {
    flex: 100%;
    text-align: center;
  }

  .flex-1 {
    flex: 100%;
    margin-top: 50px;
  }

  .background-home-2 {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .home-carousel-container {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .home-carousel-navigation {
    flex-direction: row;
    margin-right: 0;
  }

  .home-carousel-navigation button {
    text-align: center;
    font-size: 3.5vw !important;
  }

  .home-carousel-navigation button::before {
    content: "";
    /* Remove the content */
    display: none;
    /* Hide the pseudo-element */
  }

  .home-carousel-slides {
    margin-top: 20px;
    flex-direction: column;
  }

  .slide-content {
    flex-direction: column !important;
  }

  .slide-image {
    margin-left: -100px;
  }

  .slide-text {
    padding: 5px;
  }

  .background-home-3 {
    flex-direction: column-reverse;
    height: auto;
  }

  .background-home-4 {
    flex-direction: column;
    height: auto;
  }

  .background-home-5 {
    flex-direction: column-reverse;
    height: auto;
  }

  .background-home-6 {
    flex-direction: column;
    height: auto;
  }

  .background-home-7 {
    flex-direction: column-reverse;
    height: auto;
  }

  .myDIV {
    flex-direction: column !important;
  }

  .product-home {
    flex-direction: column !important;
  }

  .product-home-card {
    width: auto !important;
    margin-bottom: 15px;
    padding: 15px;
  }

  .why-choose-us {
    padding-left: 20px;
  }

  .center .CircleCarousel {
    opacity: 1;
  }

  .center .slick-center .CircleCarousel {
    transform: scale(0.7);
  }

  .background-home-8 {
    height: auto !important;
  }

  .CircleCarouselName {
    margin-right: 0px !important;
    margin-left: 40px !important;
  }
  .slide-text-1 {
    height: 400px;
  }
}
