/* Base styles for .Contact-background */
.Contact-background {
  background: linear-gradient(308deg, #40413f, #bcb595, #e68d09);
  background-size: 600% 100%;
  animation: gradient-animation 20s ease infinite;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 40px;
}

/* Keyframes for gradient animation */
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Content styles for .content-Contact */
.content-Contact {
  flex: 50%;

  color: white;
}

/* Image container styles for .image-container-Contact */
.image-container-Contact {
  flex: 50%;
}

/* Image dimensions for .image-container-Contact img */
.image-container-Contact img {
  width: 100%;
  height: 360px !important;
}

/* Contact card styles for .contact-card */
.contact-card {
  border: none !important;
  font-size: larger;
  background-color: white;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

/* Custom Contact button styles for .custom-Contact-button-1 */
.custom-Contact-button-1 {
  background-color: #fd761b;
  color: white;
  text-align: center;
  font-size: 18px;
  border-radius: 20px;
  margin-top: 30px;
  transition: background-color 0.3s; /* Add a smooth transition */
  width: 170px; /* Adjust the width as needed */
  height: 40px;
  border: none;
  font-family: "Varela Round", sans-serif !important;
}

.custom-Contact-button-1:hover {
  background-color: #ff8c40; /* Change the background color on hover */
  cursor: pointer; /* Change the cursor to a pointer on hover */
}

/* Active/Pressed state styles */
.custom-Contact-button-1:active {
  background-color: #d64d00; /* Change the background color when pressed */
}

/* HeadQuarters styles */
.headQuarters {
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 20px !important;
  margin-top: 50px;
  margin-bottom: 50px;
  overflow-y: auto;
}

/* Invalid feedback styles for .contact-us */
.contact-us .invalid-feedback {
  color: red;
}

/* Media query for screens between 350px and 1180px */
@media (min-width: 300px) and (max-width: 1200px) {
  .Contact-background {
    height: auto;

    flex-direction: column;
  }
}
