.ScrollButton {
  position: fixed;
  z-index: 2 !important;
  width: 100%;
  left: 95%;
  bottom: 80px;
  height: 20px;
  font-size: 3rem;
  cursor: pointer;
  color: #ff6600;
  animation: smoothUpDown 2s infinite linear;
}

.ScrollButton:hover {
  animation-play-state: paused;
}

@keyframes smoothUpDown {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30px);
  }
}

@media (min-width: 300px) and (max-width: 1200px) {
  .ScrollButton {
    left: 85%;
  }
}
