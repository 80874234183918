.background-Theme {
  background: linear-gradient(308deg, #40413f, #bcb595, #e68d09);
  background-size: 600% 100%;
  animation: gradient-animation 20s ease infinite;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.JavaOpen-container {
  margin-top: 50px;
  text-align: center;
  color: white;
}
.JavaOpen-container h1 {
  font-weight: bold;
}

.Content {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-left: 150px;
  padding-right: 150px;
}

.horizontal-card {
  margin-top: 50px;
  margin-left: 200px;
  margin-right: 200px;
  margin-bottom: 200px;
  box-shadow: 0px 0px 20px #dfede3;
}

.Heading {
  text-align: center;
  font-size: 4.5vmin;
  font-weight: bold;
  margin-bottom: 20px;
}

.Button {
  text-align: center;
  margin-top: 20px;
}

.custom-openings-button-1 {
  background-color: #fd761b;
  color: white;
  text-align: center;
  font-size: 18px;
  border-radius: 20px;
  margin-top: 30px;
  transition: background-color 0.3s; /* Add a smooth transition */
  width: 150px; /* Adjust the width as needed */
  height: 40px;
  border: none;
  font-family: "Varela Round", sans-serif;
}

.custom-openings-button-1:hover {
  background-color: #ff8c40; /* Change the background color on hover */
  cursor: pointer; /* Change the cursor to a pointer on hover */
}

/* Active/Pressed state styles */
.custom-openings-button-1:active {
  background-color: #d64d00; /* Change the background color when pressed */
}

@media (min-width: 300px) and (max-width: 1200px) {
  .Content {
    padding-left: 0;
    padding-right: 0;
    padding-top: 10px;
  }

  .horizontal-card {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
}
