.Careers-background {
  background: linear-gradient(308deg, #40413f, #bcb595, #e68d09);
  background-size: 600% 100%;
  animation: gradient-animation 20s ease infinite;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 40px;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.content-Careers {
  flex: 50%;
}

.image-container-Careers {
  flex: 50%;
}

.image-container-Careers img {
  width: 100%;
  height: 400px;
}

.background-career-8 {
  height: 85vh;
  margin-top: 100px;
  margin-left: 100px;
  margin-right: 100px;
  padding: 50px;
  text-align: center;
  background-color: #8ae4e7;
  border-radius: 20px;
}
.Work-Cards {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.Work1 {
  margin-top: 50px;
  height: auto;
  width: 300px;
}
.Work1 img {
  height: 8vh;
  width: auto;
  margin-bottom: 20px;
}

/* custom-button-Career */
.custom-button-Career {
  background-color: #fd761b;
  color: white;
  text-align: center;
  font-size: 18px;
  border-radius: 20px;
  margin-top: 30px;
  transition: background-color 0.3s; /* Add a smooth transition */
  width: 150px; /* Adjust the width as needed */
  height: 40px;
  border: none;
  font-family: "Varela Round", sans-serif;
}

.custom-button-Career:hover {
  background-color: #ff8c40; /* Change the background color on hover */
  cursor: pointer; /* Change the cursor to a pointer on hover */
}

/* Active/Pressed state styles */
.custom-button-Career:active {
  background-color: #d64d00; /* Change the background color when pressed */
}

.background-careers-1 {
  background: #d3d3d22a;
  height: 600px;
  margin-top: 100px;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
}
.carrer-text {
  color: rgb(0, 0, 0) !important;
  margin-left: 50px;
  margin-top: 50px;
  font-size: 28px !important;
}

.career-Nav-text1 {
  color: rgb(0, 0, 0) !important;

  font-size: 20px;
}
.nav-link {
  cursor: pointer;
}

.careerNav-link {
  margin-left: 300px;
}
.career-content {
  margin-top: 50px !important;
  display: flex;
  flex-direction: row;
}

.flex-container-careers {
  display: flex;
  align-items: center;
}

.image-container-careers {
  flex: 50%;
}

.image-container-careers img {
  width: 400px;
  margin-left: 50px;
  margin-top: 50px;
}

.career-text-container {
  flex: 50%;
  padding-right: 100px;
  margin-left: -100px;
  font-size: 22px;
  margin-top: 50px;
  color: rgb(0, 0, 0);
}

.background-careers-3 {
  background: rgb(200, 226, 239);
  height: 400px;
  display: flex;
  align-items: center;
  margin-top: 100px;
}
.content-careers1 {
  flex: 1;
  margin-right: 100px;
  margin-left: 100px;
  font-size: 22px;
}

.image-container-careers2 {
  flex: 1;
}

.image-container-careers2 img {
  height: 300px;
  margin-left: 100px;
}

.looking-perks {
  background-color: #e78abd;
  margin-top: 100px;
  margin-left: 100px;
  margin-right: 100px;
  height: 60vh;
  padding: 50px;
  border-radius: 20px;
}

/* Base styles for the background */
.background-careers-2 {
  background: #8ae4e7;
  height: 200px;
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Varela Round", sans-serif;
}

.tagline-careers {
  margin-left: 50px;
  font-weight: bold;
}

.social-icons-careers {
  margin-left: 350px;
}

.icon-careers {
  height: 80px;
  margin-right: 10px;
}

.career-container {
  text-align: center;
}

.buttons {
  border-radius: 20px;
}

.Openings {
  margin-top: 100px;
  text-align: center;
  margin-bottom: 250px;
  display: flex;
  flex-direction: column;
}

.spacedTable td {
  padding-left: 150px;
  padding-right: 150px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.spacedTable th {
  font-size: 3.8vmin;
}

.spacedTable td {
  font-size: 3vmin;
}

.Openings table {
  margin: 0 auto;
}

.hrContainer {
  display: flex;
  justify-content: center;
}

.centeredHr {
  width: 70%;
  margin-bottom: 50px;
}

@media (min-width: 300px) and (max-width: 1200px) {
  .Careers-background {
    flex-direction: column;
    height: auto;
    align-items: center;
  }

  .spacedTable td {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .custom-button-Career {
    font-size: 10px;
    border-radius: 0;
    margin-top: 20px;

    width: 70px;
    height: 30px;
  }

  .background-careers-2 {
    flex-direction: column;
    height: auto;
  }
  .social-icons-careers {
    margin-left: 0;
  }

  .background-careers-1 {
    height: auto;
  }
  .flex-container-careers {
    flex-direction: column;
  }

  .career-text-container {
    padding-right: 0;
    margin-left: 0;
    padding: 20px;
  }
  .careerNav-link {
    margin-left: 0;
  }
  .looking-perks {
    height: auto;
    margin-left: 0;
    margin-right: 0;
  }

  .Work-Cards {
    flex-direction: column;
  }
  .background-career-8 {
    height: auto !important;
    margin-left: 0;
    margin-right: 0;
  }
}
