/* Base styles for the Services background */
.Services-background {
  background: linear-gradient(308deg, #40413f, #bcb595, #e68d09);
  background-size: 600% 100%;
  animation: gradient-animation 20s ease infinite;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 40px;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.content-Services {
  flex: 50%;

  color: white;
}

.image-container-Services {
  flex: 50%;
}

.image-container-Services img {
  width: 100%;
  height: 360px !important;
}

@media (min-width: 300px) and (max-width: 1200px) {
  .Services-background {
    flex-direction: column;
    height: auto;
  }
}

.background-container {
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 50px;
}

.content {
  align-items: center;
  text-align: center;
  padding: 150px;
}

@media (min-width: 300px) and (max-width: 1200px) {
  .background-container {
    flex-direction: column;
    height: auto;
  }
  .content {
    padding: 50px;
  }
}

.Services-container-1 {
  display: flex;
  flex-direction: row;
  height: 400px;

  align-items: center;
  padding: 50px;
}

.Services-content-1 {
  flex: 50%;
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Content background with opacity */
}

.Services-image-1 {
  flex: 50%;
}

.image-Services-1 {
  height: 300px;
  width: 100%;
}

.Services-container-2 {
  display: flex;
  flex-direction: row;
  height: 400px;

  align-items: center;
  padding: 50px;
}

/* Content styles */
.Services-content-2 {
  flex: 50%;
  padding: 20px;
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Content background with opacity */
}

/* Image styles */
.Services-image-2 {
  flex: 50%;
}

/* Image dimensions and margins */
.image-Services-2 {
  height: 300px;
  width: 100%;
}

.Services-container-3 {
  display: flex;
  flex-direction: row;
  height: 400px;

  align-items: center;
  padding: 50px;
}

/* Content styles */
.Services-content-3 {
  flex: 50%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
}

/* Image styles */
.Services-image-3 {
  flex: 50%;
}

/* Image dimensions and margins */
.image-Services-3 {
  height: 300px;
  width: 100%;
}

.Services-container-4 {
  display: flex;
  flex-direction: row;
  height: 400px;

  align-items: center;
  padding: 50px;
}

.Services-content-4 {
  flex: 50%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
}

/* Image styles */
.Services-image-4 {
  flex: 50%;
}

/* Image dimensions and margins */
.image-Services-4 {
  height: 300px;
  width: 100%;
}

.Services-container-5 {
  display: flex;
  flex-direction: row;
  height: 400px;

  align-items: center;
  padding: 50px;
}

/* Content styles */
.Services-content-5 {
  flex: 50%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
}

/* Image styles */
.Services-image-5 {
  flex: 50%;
}

/* Image dimensions and margins */
.image-Services-5 {
  height: 300px;
  width: 100%;
}

.Services-container-6 {
  display: flex;
  flex-direction: row;
  height: 400px;
  align-items: center;
  padding: 50px;
}

/* Content styles */
.Services-content-6 {
  flex: 50%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
}

/* Image styles */
.Services-image-6 {
  flex: 50%;
}

/* Image dimensions and margins */
.image-Services-6 {
  height: 300px;
  width: 100%;
}

.Services-container-7 {
  display: flex;
  flex-direction: row;
  height: 400px;

  align-items: center;
  padding: 50px;
}

/* Content styles */
.Services-content-7 {
  flex: 50%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
}

/* Image styles */
.Services-image-7 {
  flex: 50%;
}

/* Image dimensions and margins */
.image-Services-7 {
  height: 300px;
  width: 100%;
}

.Services-container-8 {
  display: flex;
  flex-direction: row;
  height: 400px;
  align-items: center;
  padding: 50px;
}

/* Content styles */
.Services-content-8 {
  flex: 50%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
}

/* Image styles */
.Services-image-8 {
  flex: 50%;
}

/* Image dimensions and margins */
.image-Services-8 {
  height: 300px;
  width: 100%;
}

@media (min-width: 300px) and (max-width: 1200px) {
  .Services-container-1 {
    flex-direction: column-reverse;
    height: auto;
  }

  .Services-container-2 {
    flex-direction: column;
    height: auto;
  }

  .Services-container-3 {
    flex-direction: column-reverse;
    height: auto;
  }

  .Services-container-4 {
    flex-direction: column;
    height: auto;
  }
  .Services-container-5 {
    flex-direction: column-reverse;
    height: auto;
  }
  .Services-container-6 {
    flex-direction: column;
    height: auto;
  }
  .Services-container-7 {
    flex-direction: column-reverse;
    height: auto;
  }
  .Services-container-8 {
    flex-direction: column;
    height: auto;
  }
}

.Services-button-1 {
  background-color: #fd761b;
  color: white;
  text-align: center;
  font-size: 2.8vh;
  border-radius: 20px;
  margin-top: 30px;
  transition: background-color 0.3s; /* Add a smooth transition */
  width: 170px; /* Adjust the width as needed */
  height: 40px;
  border: none;
}

.Services-button-1:hover {
  background-color: #ff8c40; /* Change the background color on hover */
  cursor: pointer; /* Change the cursor to a pointer on hover */
}

/* Active/Pressed state styles */
.Services-button-1:active {
  background-color: #d64d00; /* Change the background color when pressed */
}
