.Etl-background {
  background: linear-gradient(308deg, #40413f, #bcb595, #e68d09);
  background-size: 600% 100%;
  animation: gradient-animation 20s ease infinite;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 40px;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.custom-Etl-button-1 {
  background-color: #ff6600;
  color: white;
  text-align: center;
  font-size: 20px;
  border-radius: 20px;
  margin-top: 30px;
  transition: background-color 0.3s; /* Add a smooth transition */
  width: 200px; /* Adjust the width as needed */
  height: 50px;
  border: none;
}

.custom-Etl-button-1:hover {
  background-color: #ff8c40; /* Change the background color on hover */
  cursor: pointer; /* Change the cursor to a pointer on hover */
}

/* Active/Pressed state styles */
.custom-Etl-button-1:active {
  background-color: #d64d00; /* Change the background color when pressed */
}

.custom-Etl-button-2 {
  background-color: #ff6600;
  color: white;
  text-align: center;
  font-size: 18px;
  border-radius: 20px;
  margin-top: 30px;
  transition: background-color 0.3s; /* Add a smooth transition */
  width: 220px; /* Adjust the width as needed */
  height: 40px;
  border: none;
}

.custom-Etl-button-2:hover {
  background-color: #ff8c40; /* Change the background color on hover */
  cursor: pointer; /* Change the cursor to a pointer on hover */
}

/* Active/Pressed state styles */
.custom-Etl-button-2:active {
  background-color: #d64d00; /* Change the background color when pressed */
}

.content-Etl {
  flex: 50%;
  color: white;
}

.image-container-Etl {
  flex: 50%;
}
.image-container-Etl img {
  width: 100%;
  height: 400px;
}

.etl-background2 {
  display: flex;
  flex-direction: row;
  margin-top: 100px;
  margin-bottom: 100px;
  background-color: #f4c2c2;
  height: 80vh;
  align-items: center;
  padding: 30px;
}

.content-etl2 {
  flex: 50%;
}

.image-container-etl2 {
  flex: 50%;
}
.image-container-etl2 img {
  width: 80%;
  height: 50vh;
}

@media (min-width: 300px) and (max-width: 1200px) {
  .Etl-background {
    flex-direction: column;
    height: auto;
    margin-top: 40px;
  }

  .etl-background2 {
    flex-direction: column !important;
    height: auto;
    align-items: center;
  }
}
