.mobile-background {
  background: linear-gradient(308deg, #40413f, #bcb595, #e68d09);
  background-size: 600% 100%;
  animation: gradient-animation 20s ease infinite;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 40px;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.image-container-mobile {
  flex: 50%;
}
.image-container-mobile img {
  width: 100%;
  height: 450px;
}

.content-mobile {
  flex: 50%;

  font-size: 3.5vh;
  color: white;
}

.custom-mobile-button-1 {
  background-color: #fd761b;
  color: white;
  text-align: center;
  font-size: 2.8vh !important;
  border-radius: 20px;
  margin-top: 30px;
  transition: background-color 0.3s; /* Add a smooth transition */
  width: 27vh !important; /* Adjust the width as needed */
  height: 7vh;
  border: none;
  font-family: "Varela Round", sans-serif !important;
}
/* Hover state styles */
.custom-mobile-button-1:hover {
  background-color: #ff8c40; /* Change the background color on hover */
  cursor: pointer; /* Change the cursor to a pointer on hover */
}

/* Active/Pressed state styles */
.custom-mobile-button-1:active {
  background-color: #d64d00; /* Change the background color when pressed */
}

.mobile-Cards {
  margin-top: 100px;

  height: 100vh;
  display: flex;
  flex-direction: row;
}

.left-card {
  position: relative;
  z-index: 1;
  left: 10%;

  text-align: left;
  display: inline-block;
  background-color: #d2f5e4 !important;
  width: 60vw;
  border: none !important;
}

.leftcard-Title {
  font-size: 6vh;
  margin-right: 10vw;
  margin-top: 6vh;
}
.leftcard-content {
  margin-top: 2vw;
  margin-bottom: 40px !important;
  margin-right: 15vw;
  font-size: 3vh;
}

.right-card {
  position: absolute;
  top: 10%;
  width: 37vw;
  right: 10%;
  background-color: #f8c0f4 !important;
  z-index: 2;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.rightcard-content {
  margin-top: 40px;
  margin-bottom: 40px !important;
  font-size: 5vh;
}

.mobile-background1 {
  background: #93daf0 !important;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;

  flex-direction: row;
}

.mobile-Quote {
  position: relative;
  font-size: 4vw;

  align-items: center;
  text-align: center;
}

.background-mobile1 {
  background: #ffffff;
  height: 800px;
  display: flex;
  align-items: flex-start;
  margin-top: 100px;
  flex-direction: row;
}
.left-column {
  flex: 30%;
  background-color: #ffffff;

  margin-top: 50px;
  padding-left: 50px;
}

.right-column {
  flex: 70%;
  padding-right: 40px;
  background-color: #ffffff;
}
.card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns with equal width */
  gap: 20px; /* Adjust the gap between cards as needed */
}
.no-border-card {
  border: none !important;
  padding: 10px;
  background-color: #f9f9f9;
}

.mobileCard-Heading {
  font-weight: bold;
  font-size: 2.8vw;
  font-family: "Varela Round", sans-serif;
}

.mobileCard-Content {
  font-size: 1.6vw;
  font-family: "Varela Round", sans-serif;
}

.background-mobile6 {
  height: 400px;
  display: flex;
  flex-direction: column;

  margin-top: 100px;
}

.mobile6-image-container {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  padding-left: 100px;
  padding-right: 100px;
}

.mobile6-image-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile6-image-card img {
  width: 140px; /* Adjust the width as needed */
  height: 100px;
}

.mobile6-image-card p {
  margin-top: 30px; /* Add desired spacing */
  text-align: center;
  font-weight: bolder;
  font-size: 25px;
}

.background-mobile2 {
  background: #ffffff;
  display: flex;
  align-items: flex-start;
  margin-top: 200px;
  margin-bottom: 200px;
}

.mobileCard-container {
  margin-bottom: 40px; /* Adjust the gap between cards as needed */
}

.App {
  margin-top: 50px;
  text-align: center;
}

.title {
  margin-bottom: 50px;
}

.image_wrapper {
  margin-right: 20px;
  margin-left: 20px;
}

.image_wrapper img {
  width: 70px;
  height: 50px;
}

@media (min-width: 300px) and (max-width: 1200px) {
  .mobile-background {
    flex-direction: column;
    height: auto;
    align-items: center;
  }

  .mobile-Cards {
    flex-direction: column;
    height: auto !important;
  }

  .left-card {
    flex-direction: column;
    left: 0;
    width: auto;
  }

  .right-card {
    flex-direction: column;
    right: 0;
    top: 0;
    width: auto;
  }

  .mobile-background1 {
    flex-direction: column;
    height: auto;
  }
  .mobile-Quote {
    flex-direction: column;
    left: 0;
  }
  .background-mobile1 {
    flex-direction: column;
    height: auto;
  }

  .left-column {
    margin-top: 0;
    padding-left: 10px;
  }

  .background-mobile6 {
    height: auto;
  }
}

.content-mobile1 {
  flex: 1;
  margin-right: 100px;
  margin-left: 100px;
  font-size: 22px;
  color: white;
}

.image-container-mobile1 {
  flex: 1;
}
.image-container-mobile1 img {
  max-width: 100%;
  height: 450px;
  margin-left: 50px;
}

.content-mobile2 {
  flex: 1;
  margin-right: 100px;
  margin-left: 100px;
  font-size: 22px;
  color: white;
}

.image-container-mobile2 {
  flex: 1;
}
.image-container-mobile2 img {
  max-width: 100%;
  height: 300px;
  margin-left: 100px;
}

.mobile-service1 {
  background: rgba(255, 255, 255, 0.2);
  height: 400px;
  width: 600px;
  margin-left: 100px;
  margin-right: 100px;
  border-radius: 20px;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
}

/* IOS CSS */
.background-mobile3 {
  background: #ffffff;
  height: 600px;

  display: flex;
  align-items: center;
}

.content-mobile3 {
  flex: 1;
  margin-right: 100px;
  margin-left: 100px;
  font-size: 22px;
  color: rgb(0, 0, 0);
}

.image-container-mobile3 {
  flex: 1;
}
.image-container-mobile3 img {
  max-width: 100%;
  height: 300px;
  margin-left: 100px;
}

.mobile-service2 {
  background: rgba(255, 255, 255, 0.2);
  height: 400px;
  width: 600px;
  margin-left: 100px;
  margin-right: 100px;
  border-radius: 20px;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
}

/* Windows app Css */

.background-mobile4 {
  background: white;
  height: 500px;
  display: flex;

  align-items: center;
}

.content-mobile4 {
  flex: 1;
  margin-right: 100px;
  margin-left: 100px;
  font-size: 22px;
  color: rgb(0, 0, 0);
}

.image-container-mobile4 {
  flex: 1;
}
.image-container-mobile4 img {
  max-width: 100%;
  height: 300px;
  margin-left: 100px;
}

.mobile-service3 {
  background: rgba(255, 255, 255, 0.2);
  height: 400px;
  width: 600px;
  margin-left: 100px;
  margin-right: 100px;
  border-radius: 20px;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
}

.background-mobile5 {
  background: #322e2f;
  height: 500px;
  display: flex;

  align-items: center;
}

.content-mobile5 {
  flex: 1;
  margin-right: 100px;
  margin-left: 100px;
  font-size: 22px;
  color: white;
}

.image-container-mobile5 {
  flex: 1;
}
.image-container-mobile5 img {
  max-width: 100%;
  height: 300px;
  margin-left: 100px;
}

.mobile-service4 {
  background: rgba(255, 255, 255, 0.2);
  height: 400px;
  width: 600px;
  margin-left: 100px;
  margin-right: 100px;
  border-radius: 20px;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
}
