.blog {
  background: linear-gradient(308deg, #40413f, #bcb595, #e68d09);
  background-size: 600% 100%;
  animation: gradient-animation 20s ease infinite;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Blog-Cards {
  padding-top: 100px;
  padding-left: 100px;
  padding-right: 100px;
}

.Blog-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 46px;
}

.Tags button {
  margin-left: 10px;
  margin-bottom: 20px;
  background-color: transparent;
  height: 40px;
  border: 2px solid #fd761b;
  transition: background-color 0.3s, transform 0.3s;
  font-family: "Varela Round", sans-serif;
}

.Tags button:hover {
  background-color: #fd761b;
  transform: translateY(-10px);
}

.Blog-card {
  height: auto !important;
  width: 350px;
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  text-align: center;
  border-radius: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 20px;
}

.Image-Blogscards1 {
  height: 150px;
  width: 100%;
}

.Card-text {
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 20px;
  font-family: "Varela Round", sans-serif;
  text-align: left;
}

.Card-writer {
  font-size: 15px;
  text-align: left;
  font-family: "Varela Round", sans-serif;
  padding-left: 20px;
  padding-right: 20px;
  color: gray;
}

.Main-Blog-Post {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px;
}

.Blog-Content {
  flex: 20%;
  margin-left: 20px;
}

.Blog-Post {
  flex: 80%;
  height: fit-content;
  max-width: 900px;
  /* Set your desired maximum width in pixels or any other unit */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

  margin: 0 auto;
  /* Center the element horizontally on the page */
}

.Blog-Post img {
  width: 100%;
  height: auto;
}

.RecentBlogImage img {
  height: 50px;
  width: 80px;
  border-radius: 10px;
}

.Image-Blogscards2 {
  height: 150px !important;
  width: 100% !important;
  display: flex;
  flex-direction: column !important;
}

.Recent-Blog {
  height: fit-content;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 20px;
}

.Recent-Blog li :hover {
  cursor: pointer;
  color: #fd761b;
}

.Blog-archive {
  height: fit-content;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 20px;
  margin-top: 10px;
}

.Blog-archive li:hover {
  cursor: pointer;
  color: #fd761b;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
}

/* Contact button styles */
.pagination button {
  /* margin-bottom: 10px; */
  border-radius: 20px !important;

  width: 120px;
  /* Adjust the width as needed */
  height: 40px;
  background-color: #fd761b;
  border: none;
  font-size: 20px;
  color: white;
}

.pagination button:hover {
  background-color: #ff8c40;
  /* Change the background color on hover */
  cursor: pointer;
  /* Change the cursor to a pointer on hover */
}

/* Active/Pressed state styles */
.pagination button:active {
  background-color: #d64d00;
  /* Change the background color when pressed */
}

@media (min-width: 300px) and (max-width: 1200px) {
  .Blog-Post {
    padding: 10px;
  }

  .Main-Blog-Post {
    flex-direction: column;
    padding: 20px;
  }

  .Recent-Blog {
    display: none;
  }

  .Blog-archive {
    display: none;
  }

  .Blog-Cards {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
  }
}
