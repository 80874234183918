.about-background {
  background: linear-gradient(308deg, #40413f, #bcb595, #e68d09);
  background-size: 600% 100%;
  animation: gradient-animation 20s ease infinite;
  height: 100vh;

  align-items: center;
  padding: 40px;
  display: flex !important;
  flex-direction: row;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.about-1 {
  flex: 50%;

  color: white;
}

.image-about {
  flex: 50%;
}

.image-about img {
  width: 100%;
  height: 450px;
}

.passion-box {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 140px;
  /* border: 1px solid rgb(126, 117, 117); */
}

.passion-box {
  margin: 15px 0;
}
.passion-box img {
  margin-bottom: 15px;
}
img {
  border-style: none;
  vertical-align: middle;
}

.content-container-about {
  margin-top: 50px;

  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
  align-items: center !important;
}

.image-container-about {
  flex: 40%;
}

.text-container-about {
  flex: 60%;
  padding: 30px;
}

.image-container-about img {
  margin-top: 30px;
  width: 100%;
  height: 50%;
  padding: 20px;
}

.background-about-1 {
  background: #a284f5;
  height: 400px;
  margin-top: 50px;

  display: flex;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  border-radius: 50px;
  align-items: center;
}

.flex-container-about {
  flex: 50%;
  padding: 40px;
}

.flex-container-about-1 {
  flex: 50%;
  padding: 20px;
}
.image-about-2 {
  height: 85%;
  width: 100%;
}

.box-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.box {
  width: 120px;
  height: 120px;
  background-color: transparent;
  border: 1px solid;
  border-radius: 30px 0 30px 30px;

  transition: border-color 0.5s ease, border-radius 0.5s ease;
}

.box:hover {
  border-color: #fd761b;
  border-radius: 0 30px 30px 30px;
}

.box-text {
  text-align: center;
  font-weight: bolder;
  font-size: 15px;
  color: #555555;
}

/* Base styles for the background */
.background-about-2 {
  background: #884ab2;
  height: 350px;
  padding: 30px;
  margin-top: 100px;
  margin-left: 100px;
  margin-right: 100px;

  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  border-radius: 50px;
  font-family: "Varela Round", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Text styles */
.about-text-1 {
  color: white;
  font-weight: bolder;
  font-size: 5vh;
}

.about-text-2 {
  color: white;

  font-size: 20px;
  padding-top: 20px;
}

.background-about-3 {
  background: #fff;
  display: flex;
  flex-direction: column;

  height: 100%;
  margin-top: 100px;

  align-items: center;
}
.certificate-container {
  text-align: center;
  padding-bottom: 5%;
}

.box-container-2 {
  display: flex;

  gap: 30px !important;
}

.box-2 {
  width: 200px;
  height: 200px;
  background-color: transparent;
  border: 1px solid;
  transition: border-color 0.5s ease, border-radius 0.5s ease;
}

.box-2:hover {
  border-color: #000000;
  border-radius: 0 30px 0px 30px;
  background-color: #ced9f7;
}

.box-img1 {
  margin-left: 40px;
  margin-top: 10px;
  width: 120px;
  height: 80px;
}

.box-img2 {
  margin-top: 10px;
  margin-left: 60px;
  height: 80px;
}

.box-img3 {
  margin-top: 10px;
  margin-left: 30px;
  width: 140px;
  height: 80px;
}

.box-img4 {
  margin-left: 40px;
  margin-top: 10px;
  width: 120px;
  height: 80px;
}

.box-2 p {
  text-align: center !important;
  font-size: 22px;
  font-weight: lighter;
  color: #555;
}

.background-about-6 {
  margin-top: 100px;
  width: 100%;
  height: 100vh;
  background-color: #dfd4eeb9;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-about {
  flex: 50%;

  padding: 20px;
}
.flex-about-2 {
  flex: 50%;
}
.background-about-7 {
  height: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
}
.ourclients {
  flex: 50%;
}
.ourclients p {
  text-align: center;
  font-size: 4.5vmin !important;
  padding: 0px 100px 0px 100px;
}
.ourclients h1 {
  text-align: center;
}

.background-about-8 {
  display: flex;
  flex-direction: row;
  height: 350px;
  background-color: #489ba7;
}

.about-button {
  background-color: #fd761b;
  color: white;
  text-align: center !important;
  font-size: 18px;
  border-radius: 20px;
  font-family: "Varela Round", sans-serif;
  margin-top: 30px;
  transition: background-color 0.3s; /* Add a smooth transition */
  width: 170px; /* Adjust the width as needed */
  height: 40px;
  border: none;
}

.about-button:hover {
  background-color: #ff8c40; /* Change the background color on hover */
  cursor: pointer; /* Change the cursor to a pointer on hover */
}

/* Active/Pressed state styles */
.about-button:active {
  background-color: #d64d00; /* Change the background color when pressed */
}

@media (min-width: 300px) and (max-width: 1200px) {
  .about-background {
    flex-direction: column !important;
    height: auto;
    align-items: center;
  }

  .content-container-about {
    flex-direction: column;
  }

  .background-about-8 {
    flex-direction: column;
    height: auto;
  }

  .background-about-1 {
    flex-direction: column-reverse;
    height: auto;
  }
  .box-container {
    flex-direction: column;
    gap: 20px;
  }
  .box-container-2 {
    flex-direction: column;
  }

  .background-about-6 {
    flex-direction: column;
    height: auto !important;
  }
  .ourclients {
    flex-direction: column;
  }
  .ourclients p {
    padding: 0px 20px 0px 20px;
  }

  .background-about-2 {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    height: auto;
  }

  .about-button {
    width: auto !important;
  }
}
