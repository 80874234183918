.section1 {
  background-image: url(../Assets/Background4.jpg);
  background-size: cover; /* or use "contain" for a different effect */
  background-position: center center;
  background-repeat: no-repeat;
  height: 700px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 50px;
}
.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio while covering the entire container */
  z-index: -1; /* Place the video behind other content */
}

.content-overlay {
  position: relative;
  z-index: 1; /* Place the content above the video */
  text-align: center;
  color: white; /* Adjust text color for better visibility */
}

.content-overlay h1,
.content-overlay button {
  position: relative;
  z-index: 2; /* Ensure text is above the video */
}
.section1 h1 {
  padding-top: 200px;
  margin-bottom: 50px;
  font-size: 50px;
  color: white;
  font-family: "Varela Round";
  font-weight: bold;
}
.button {
  width: 150px;
  height: 40px;
  border-radius: 20px;
  border: none;
  background-color: #960e0e;
  color: white;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.button:hover {
  background-color: transparent;
  border: 2px solid rgb(150, 14, 14);
  color: black;
}
.button1 {
  width: 200px;
  height: 60px;
  border-radius: 10px;
  border: none;
  font-size: 25px;
  font-weight: bolder;
  background-color: #960e0e;
  color: white;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.button1:hover {
  background-color: transparent;
  border: 2px solid rgb(150, 14, 14);
  color: white;
}
.section2 {
  height: 400px;
  background-color: #022139;
  /* background-image: url(../Assets/Background.jpg); */
}

.Cards {
  padding-top: 30px;
  display: flex;
  justify-content: space-around !important;
}
.Cards div {
  width: 200px;
  height: 100%;
  text-align: left;
}
.Cards h3 {
  font-family: "Varela Round";
  color: white;
  font-weight: bold;
}
.Cards p {
  color: white;
}

.section3 {
  height: 550px;
  display: flex;
  padding: 50px;
  align-items: center;
  background-color: rgb(247, 243, 243);
}
.section3-left {
  flex: 50%;
}
.section3-right {
  flex: 50%;
}
.form-group {
  margin-bottom: 15px;
}
.form-group textarea {
  width: 100%;
  border: none;
  background-color: rgb(247, 243, 243);
  border-bottom: 1px solid black;
}
.form-group input {
  width: 100%;
  border: none;
  background-color: rgb(247, 243, 243);
  border-bottom: 1px solid black;
}
.form-card {
  width: 100%;
  height: 100%;
  padding: 30px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.section4 {
  height: 500px;
  display: flex;
  padding: 50px;
  background-color: rgb(247, 243, 243);
}

.section4-left {
  flex: 50%;
}
.section4-right {
  flex: 50%;
}
.live-class-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
}
.live-class-container div h4 {
  font-family: "Varela Round";
}

.section5 {
  height: 500px;
  text-align: center;
  padding: 50px;
  background-color: #022139;
  /* background-image: url(../Assets/Background.jpg); */
}

.Cards-2 {
  padding-top: 50px;
  display: flex;
  justify-content: space-around !important;
}
.Cards-2 div {
  width: 250px;
  height: auto;
  text-align: center;
  font-size: 14px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.Cards-2 h3 {
  font-family: "Varela Round";
}
.Cards-2 p {
  color: white;
}

.section6 {
  height: 500px;
  display: flex;
  flex-direction: column;
  padding: 50px;
  background-color: rgb(247, 243, 243);
}
.section7 {
  height: 400px;
  display: flex;
  flex-direction: column;
  padding: 50px;
  background-color: rgb(247, 243, 243);
}

.Cards-3 {
  padding-top: 50px;
  display: flex;
  justify-content: space-around !important;
}
.Cards-3 div {
  width: 250px;
  height: auto;
  text-align: center;
}
.Cards-3 div h3 {
  margin-bottom: 20px !important;
}

.section8 {
  height: 500px;
  display: flex;
  padding: 50px;
  align-items: center;
  background-color: #022139;
}

.section8-left {
  flex: 50%;
}
.section8-right {
  flex: 50%;
}
.figures {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.figures h1 {
  color: #960e0e;
}
.figures p {
  color: white;
}
.section8-right img {
  height: 400px;
  width: 100%;
}
.section3-left ul {
  list-style-type: none;
  padding: 0;
}

@media (min-width: 300px) and (max-width: 1200px) {
  .section3 {
    flex-direction: column;
    height: auto;
  }
  .section8 {
    height: auto;
    flex-direction: column;
  }
  .Cards-3 {
    flex-direction: column;
  }
  .section7 {
    height: auto;
    align-items: center;
  }
  .section4 {
    flex-direction: column;
    height: auto;
  }
  .Cards-2 {
    flex-direction: column;
    align-items: center;
  }
  .section5 {
    height: auto;
  }
  .section2 {
    height: auto;
  }
  .Cards {
    flex-direction: column;
    align-items: center;
  }

  .section1 {
    height: auto;

    padding: 50px;
  }
  .section1 h1 {
    margin-top: 0;
    padding-top: 50px;
  }
  .section6 {
    height: auto;
  }
  .live-class-container {
    flex-direction: column;
    padding: 10px;
  }
}
