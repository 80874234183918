.typed-element {
  font-family: "Varela Round", sans-serif;
  color: #ffffff; /* Change the font color */
  font-size: 50px; /* Change the font size */
}
.typed-element::after {
  content: "|"; /* Set the cursor character */
  color: #f8f8f8; /* Change the cursor color */
  font-size: 50px; /* Change the cursor size */
}
