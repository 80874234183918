.Product-background-1 {
  background: linear-gradient(308deg, #40413f, #bcb595, #e68d09);
  background-size: 600% 100%;
  animation: gradient-animation 20s ease infinite;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 40px;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.content-Product {
  flex: 50%;
}

.image-container-Product {
  flex: 50%;
}
.image-container-Product img {
  width: 100%;
  height: 360px !important;
}

.custom-button-Product {
  background-color: #fd761b;
  color: white;
  text-align: center;
  font-size: 20px;
  border-radius: 20px;
  margin-top: 30px;
  transition: background-color 0.3s;
  /* Add a smooth transition */
  width: 200px;
  /* Adjust the width as needed */
  height: 50px;
  border: none;
}

.custom-button-Product:hover {
  background-color: #ff8c40; /* Change the background color on hover */
  cursor: pointer; /* Change the cursor to a pointer on hover */
}

/* Active/Pressed state styles */
.custom-button-Product:active {
  background-color: #d64d00; /* Change the background color when pressed */
}

.background-Product-8 {
  height: 400px;
  margin-top: 200px;
  display: flex;
}

.custom-button-Product-1 {
  background-color: #fd761b;
  color: white;
  text-align: center;
  font-size: 18px;
  border-radius: 20px;
  margin-top: 30px;
  transition: background-color 0.3s;
  width: 170px; /* Adjust the width as needed */
  height: 40px;
  border: none;
}

.custom-button-Product-1:hover {
  background-color: #ff8c40; /* Change the background color on hover */
  cursor: pointer; /* Change the cursor to a pointer on hover */
}

/* Active/Pressed state styles */
.custom-button-Product-1:active {
  background-color: #d64d00; /* Change the background color when pressed */
}

.product-background3 {
  margin-top: 100px;
  margin-bottom: 200px;
  background-color: #a79ff0;
  height: 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 100px;
  margin-right: 100px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 30px;
}

.content-product3 {
  flex: 50%;
  padding: 5vw;
}

.image-container-product3 {
  flex: 50%;
}

.image-container-product3 img {
  width: 100%;
  height: 50vh;
  animation: upAndDownAnimation 2s ease-in-out infinite;
}

.product-background4 {
  margin-top: 100px;
  margin-bottom: 200px;
  background-color: #f5a209;
  height: 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 100px;
  margin-right: 100px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 30px;
}

.content-product4 {
  flex: 50%;
  padding: 5vw;
  font-size: 3.5vh;
}

.image-container-product4 {
  flex: 50%;
}
.image-container-product4 img {
  width: 100%;
  height: 50vh;
  animation: upAndDownAnimation 2s ease-in-out infinite;
}

@keyframes upAndDownAnimation {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

@media (min-width: 300px) and (max-width: 1200px) {
  .Product-background-1 {
    height: auto;
    flex-direction: column;
  }

  .product-background3 {
    flex-direction: column;
    margin-left: 50px;
    margin-right: 50px;
    height: auto !important;
  }

  .image-container-product3 {
    height: 200px;
    margin-top: 40px;
  }

  .custom-button-Product-1 {
    width: 150px;
    /* Adjust the width as needed */
    height: 30px;
    font-size: 15px;
  }

  .product-background4 {
    flex-direction: column;
    margin-left: 50px;
    margin-right: 50px;
    height: auto !important;
  }

  .content-product4 {
    display: flex;
    flex-direction: column;
    font-size: 10px;
  }

  .image-container-product4 {
    height: 200px;
    margin-top: 40px;
  }

  .background-Product-8 {
    height: auto;
  }
}

@media (min-width: 300px) and (max-width: 1200px) {
  .Product-background-1 {
    flex-direction: column;
    height: auto;
  }

  .image-container-product3 img {
    height: 35vh;
  }
  .image-container-product4 img {
    height: 35vh;
  }
}
