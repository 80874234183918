/* Base styles for the navbar */
.navbar {
  font-family: "Varela Round", sans-serif;
  font-size: 18px;
  height: 80px;
  font-weight: bolder;
  width: auto !important;
  transition: background-color 1.5s ease;
  padding: 20px !important;
}

/* Logo styles */
.logo {
  width: 210px;
  /* padding-left: 12px !important; */
}

/* Services dropdown styles */
.services-dropdown:hover .dropdown-menu {
  display: block;
  animation: dropdown-fall 0.8s ease-in-out forwards;
  transform-origin: top;
}

@keyframes dropdown-fall {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Contact button styles */
.contact-button {
  /* margin-bottom: 10px; */
  border-radius: 20px !important;

  width: 170px !important; /* Adjust the width as needed */
  height: 40px !important;
  background-color: #fd761b;
  border: none;
  font-size: 18px;
  color: white;
  font-weight: bold;
}

.contact-button:hover {
  background-color: #ff8c40; /* Change the background color on hover */
  cursor: pointer; /* Change the cursor to a pointer on hover */
}

/* Active/Pressed state styles */
.contact-button:active {
  background-color: #d64d00; /* Change the background color when pressed */
}

.Learn-button {
  /* margin-bottom: 10px; */
  border-radius: 10px;
  width: 100px !important; /* Adjust the width as needed */
  height: 40px !important;
  background-color: #990926;
  border: none;
  font-size: 18px;
  color: white;
  font-weight: bold;
}

.Learn-button:hover {
  background-color: transparent;
  border: 4px solid #990926;
  color: #ffffff; /* Change the background color on hover */
  cursor: pointer; /* Change the cursor to a pointer on hover */
}

/* Telephone icon styles */
.telephone-icon {
  margin-right: 5px;
}

/* Navigation link styles */
.nav-link {
  position: relative;
  display: inline-block;
  color: #000;
  text-decoration: none;
  align-items: center !important;
}

.nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 2px;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}

.nav-link.active,
.nav-link:hover {
  color: #ffffff;
}

.nav-link.active::after,
.nav-link:hover::after {
  background-color: #ffffff;
}

/* Dropdown item styles */
.dropdown-item.active {
  background-color: #f8f9fa;
}

/* Services dropdown styles */
.services-dropdown {
  position: relative;
  /* height: 60px !important; */
}

/* Dropdown menu styles */
.dropdown-menu {
  width: 300px;
  border-radius: 20px !important;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

/* Services content styles */
.services-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  font-weight: bold !important;
  display: block;
}

.services-content a:hover {
  background-color: transparent;
  border-radius: 10px;
  color: rgb(255, 115, 0);
}

/* Media query for responsiveness */
@media (min-width: 200px) and (max-width: 650px) {
  /* Adjustments for screens below 650px in width */
  .navbar {
    font-size: 18px; /* Adjust font size for smaller screens */
    height: 80px;
  }

  .logo {
    width: 160px; /* Adjust logo size for smaller screens */
  }

  .contact-button {
    margin-right: 20px; /* Adjust margin for smaller screens */
    margin-bottom: 0;
  }

  .telephone-icon {
    margin-right: 3px; /* Adjust margin for smaller screens */
  }

  .dropdown-menu {
    width: 220px;
  }

  .contact-button {
    width: auto;
  }
}
