.learnnavbar {
  height: 80px !important;
  background-color: transparent;
}
.scrolled-navbar {
  background-color: #022139 !important; /* Add !important to override inline styles */
  transition: background-color 0.5s ease;
}
.Learnnavbarbrand {
  margin-left: 35px;
  color: white;
  font-weight: bold;
  font-size: 30px !important;
  font-family: "Varela Round";
}
.Learnnavbarbrand:hover {
  text-decoration: underline;
}

.Learnnav-item {
  color: white;
  margin-right: 20px;
  cursor: pointer;
  font-weight: bold;
}
.Learnnav-item:hover {
  text-decoration: underline;
}
@media (min-width: 300px) and (max-width: 1200px) {
  .Learnnavbarbrand {
    margin-left: 20px;
  }
}
