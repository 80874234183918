/* Base styles for the image */

.image-container {
  background-image: url("../assets/BackgroundImages/world.jpg");

  position: relative;
  width: 100%;
  height: 40vmax;
  display: flex;
  flex-direction: row;
}

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.774
  ); /* Adjust the color and opacity as needed */
  z-index: 1;
}
.image-container h5,
.image-container ul {
  position: relative;
  z-index: 2; /* Place the text above the overlay */
  color: white; /* Set text color for better readability */
}
.image-container a,
.image-container span,
.image-container img {
  position: relative;
  z-index: 2;
}

/* Margin adjustments for upper and lower rows */
.upperRow {
  display: flex;
  flex-direction: row;
  margin-top: 50px !important;
  margin-bottom: 10px;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.lowerRow {
  display: flex;
  flex-direction: row;
  margin-top: 50px !important;
  margin-bottom: 10px;
  margin-left: 20px !important;
  margin-right: 10px !important;
}
.company-footer {
  padding-left: 150px !important;
  padding-top: 30px !important;
}
.company-footer-2 {
  margin-left: -80px;
  padding-top: 30px;
}
.company-footer-3 {
  margin-left: 80px !important;
  padding-top: 75px;
}
.icon {
  position: relative;

  z-index: 2;
}

/* Footer styles */
.footer-bar {
  background-color: rgb(231, 223, 223);
  height: 25px !important;
  font-family: "Varela Round", sans-serif;
}

.footer-text {
  text-align: center;
}
.social-icons {
  display: flex;
  align-items: center;
  gap: 20px; /* Adjust the gap between icons */
}

.company-logo {
  width: 260px;
}

@media (min-width: 300px) and (max-width: 1200px) {
  .image-container {
    flex-direction: column;
    height: auto;
  }

  .company-footer {
    padding-left: 0px !important;
  }
  .company-footer-2 {
    margin-left: 0 !important;
  }

  .upperRow {
    flex-direction: column;
  }

  .company-footer-3 {
    margin-left: 0px !important;
    padding-top: 0px;
  }

  .footer-text {
    font-size: 4vw;
  }
}
